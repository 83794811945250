@media (max-width: 800px) {
    .card-img-top  {
        width: 100%;
        height: 28vh;
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
     
    }

    .card-img{
        width: 100%;
        height: 28vh;
    }
}


.card{
    margin: .85rem .3rem!important;

}

.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row{
    width: fit-content;
}

.card-img{
    width: 100%;
    height: 28vh;
}

.card-img-top  {

    width: 100%;
    height: 35vh;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
 }
    
.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

/* centered columns styles */
.row-centered {
    text-align:center;
}

.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}

.footer{
    background-color: #fff;
    color: #fff;
    margin:0px auto;
    padding: 20px 30px 20px 0px;
}

.jumbotron {
    padding:60px 30px 60px 30px  !important;
    margin:0px auto  !important;
    background:#33ACFF!important;
    color:floralwhite;
    border-radius: 0%!important;
}

address {
    font-size:80%;
    margin:0px;
    color:#33ACFF;
}

.navbar-dark {
    background-color: #fff !important;
}

.navbar-toggler {
    background-color: #33ACFF !important;
    color: '#fcfcf'!important;
}

.navbar-dark .mr-auto a{
    color: #33ACFF !important;;
}

.navbar-brand{
    color: #33ACFF;
}

.App {
    background-color: #fff!important;
    font-size: .75rem;
}

.auth-wrapper{
    padding: 50px;
    max-width: 550px;
    align-content: center;
}

.btn.btn-primary.btn-block{
    background-color: #33ACFF;
    border-color: #33ACFF;
}

.breadcrumb {
    background-color: #f8f8f8 !important;
}
